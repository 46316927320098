import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import Header from "./Components/Header";
import Preloader from "./Components/Preloader";
import Footer from "./Components/Footer";
import Sections from "./Components/Sections";
import Guide from "./Components/Guide";
import { Routes, Route } from "react-router-dom";
import { useGetSiteEndpoint } from "./_utils";

const socket = io.connect(process.env.REACT_APP_HH_URL);

function App() {
  const [site, setSite] = React.useState();
  const location = useLocation();
  const pageIdentifier = location.pathname.substring(1);
  const hhEndpoint = useGetSiteEndpoint();

  useEffect(() => {
    if (process.env.REACT_APP_IS_PROD === "true") return;

    const contentSiteId = process.env.REACT_APP_SITE_ID;
    const onSiteUpdated = async () => {
      try {
        const res = await fetch(hhEndpoint);
        const updatedSite = await res.json();
        setSite(updatedSite);
      } catch (error) {
        console.log(error);
      }
    };

    socket.emit("ContentSite-Join", contentSiteId);
    socket.on("StageUpdated", onSiteUpdated);
    return () => {
      socket.emit("ContentSite-Leave", contentSiteId);
      socket.off(contentSiteId, onSiteUpdated);
    };
  }, [hhEndpoint]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, [pageIdentifier]);

  useEffect(() => {
    async function fetchSite() {
      try {
        const res = await fetch(hhEndpoint);
        return await res.json();
      } catch (error) {
        console.log(error);
      }
    }
    if (!site) {
      fetchSite()
        .then((resp) => {
          setSite(resp);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [hhEndpoint, site]);

  if (!site) return <Preloader />;

  const { content = {} } = site;
  const { pages = [], header = {}, footer = {}, globals = {} } = content;
  const page = pages.find((p) => p.identifier === pageIdentifier) || pages[0] || {};
  const { sections = [] } = page;

  const absBanners = ["SECTION_BANNERALT", "SECTION_BANNERALT4", "SECTION_BANNERMAIN"];
  const bannerType = sections.length > 0 ? sections[0].type : "Unknown";
  const navbarPosition = absBanners.includes(bannerType) ? "position-absolute" : "";
  const navbarStartLight = ["SECTION_BANNERALT", "SECTION_BANNERALT4", "SECTION_BANNERMAIN"].includes(bannerType);
  const navbarTextColour = navbarStartLight ? "dark" : "light";
  const headervm = { ...header, globals, navbarStartLight, navbarPosition, navbarTextColour };
  const footervm = { ...footer, globals, links: header?.links };

  return (
    <>
      <Header header={headervm} globals={globals} pageIdentifier={pageIdentifier} />

      <Routes>
        <Route path="guide" element={<Guide />} />
        <Route path="*" element={<Sections sections={sections} />} />
      </Routes>

      <Footer globals={globals} footer={footervm} />
      <button
        className="scroll-top"
        onClick={() =>
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          })
        }
      >
        <i className="bi bi-arrow-up-short"></i>
      </button>
    </>
  );
}

export default App;
